import ApiService from "@/core/services/api.service";

const state = {
  status: null,
  salesPersons: [],
  housingIndustries: [],
  unlockedStatusGroups: ["gnv", "buildconn", "ne4", "product", "offer"],
  filter: {}
};

const mutations = {
  setStatus: (state, status) => {
    state.status = status;
  },

  setSalesPersons: (state, salesPersons) => {
    state.salesPersons = salesPersons;
  },

  setHousingIndustries: (state, housingIndustries) => {
    state.housingIndustries = housingIndustries;
  },

  setActiveStatusGroup: (state, statusGroup) => {
    state.activeStatusGroup = statusGroup;
  },

  setActiveStatusArray: (state, statusArray) => {
    state.activeStatusArray = statusArray;
  },

  setFilter: (state, filter) => {
    state.filter = filter;
  },

  setStatusFilter: (state, statusFilter) => {
    state.filter.status = statusFilter;
  },

  setSalesPersonsFilter: (state, salesPersonsFilter) => {
    state.filter.salesPersons = salesPersonsFilter;
  },

  setMapToolFilter: (state, addressIds) => {
    state.filter.mapTools = addressIds;
  },

  setDwellingUnitsFilter: (state, dwellingUnitsFilter) => {
    state.filter.dwellingUnits = dwellingUnitsFilter;
  },

  setHousingIndustryFilter: (state, housingIndustryFilter) => {
    state.filter.housingIndustryFilter = housingIndustryFilter;
  }
};

const actions = {
  async updateStatus({ commit, state }) {
    let status = state.status;

    await ApiService.get("status").then(response => {
      status = response.data;
    });

    commit("setStatus", status);
  },

  async updateSalesPersons({ commit }) {
    let salesPersons;

    await ApiService.get("sales-persons").then(response => {
      salesPersons = response.data.items;

      // Alphabetical sorting of those responsible
      salesPersons.sort((a, b) => {
        const nameA = `${a.firstName} ${a.lastName}`.toUpperCase();
        const nameB = `${b.firstName} ${b.lastName}`.toUpperCase();

        return nameA.localeCompare(nameB);
      });

      salesPersons.unshift({
        id: 0,
        firstName: "Nicht",
        lastName: "Zugewiesen"
      });
    });

    commit("setSalesPersons", salesPersons);
  },

  async updateHousingIndustries({ commit, getters }) {
    let housingIndustries;

    let addresses = await getters.addresses();

    // await ApiService.get("housing-industries").then(response => {
    //   housingIndustries = response.data.items;
    // });

    housingIndustries = addresses
      .map(a => {
        return a.address.housingIndustry;
      })
      .filter((v, i, a) => a.indexOf(v) === i)
      .filter(obj => !!obj);

    commit("setHousingIndustries", housingIndustries);
  },

  setActiveStatusGroup({ commit }, statusGroup) {
    commit("setActiveStatusGroup", statusGroup);
  },

  setActiveStatusArray({ commit }, statusArray) {
    commit("setActiveStatusArray", statusArray);
  },

  setFilter({ commit }, filter) {
    commit("setFilter", filter);
  },

  setStatusFilter({ commit }, statusFilter) {
    commit("setStatusFilter", statusFilter);
  },

  setSalesPersonsFilter({ commit }, salesPersonsFilter) {
    commit("setSalesPersonsFilter", salesPersonsFilter);
  },

  setMapToolFilter({ commit }, addressIds) {
    commit("setMapToolFilter", addressIds);
  },

  setDwellingUnitsFilter({ commit }, dwellingUnitsFilter) {
    commit("setDwellingUnitsFilter", dwellingUnitsFilter);
  },

  setHousingIndustryFilter({ commit }, housingIndustry) {
    commit("setHousingIndustryFilter", housingIndustry);
  }
};

const getters = {
  status: state => {
    let allStatus = state.status;
    let filteredStatus = state.status;

    if (allStatus) {
      filteredStatus.meta.overall = Object.keys(allStatus.meta.overall)
        .filter(key => state.unlockedStatusGroups.includes(key))
        .reduce((obj, key) => {
          obj[key] = allStatus.meta.overall[key];
          return obj;
        }, {});

      filteredStatus.items = allStatus.items.filter(function(el) {
        return state.unlockedStatusGroups.includes(el.name);
      });
    }

    return filteredStatus;
  },

  salesPersons: state => state.salesPersons,

  housingIndustries: state => state.housingIndustries,

  filter: state => state.filter,

  statusGroups(state) {
    let result = [];

    let statusMeta = state.status ? state.status.meta.overall : {};

    for (const [key, value] of Object.entries(statusMeta)) {
      if (state.unlockedStatusGroups.includes(key)) {
        result.push({
          text: value.form.de_DE.caption,
          value: {
            key,
            statusGroup: value
          }
        });
      }
    }

    return result;
  },

  statusGroupFilterCount: state => () => {
    const statusFilter = state.filter.status;
    let counter = 0;

    if (statusFilter) {
      for (const statusGroup of Object.entries(statusFilter)) {
        if (statusGroup[1].length > 0) {
          counter++;
        }
      }
    }

    return counter;
  },

  firstStatusGroupKey: state => () => {
    const statusFilter = state.filter.status;

    if (statusFilter) {
      for (const [key, value] of Object.entries(statusFilter)) {
        if (value.length > 0) {
          return key;
        }
      }
    }

    return null;
  },

  salesPersonById: state => id => {
    return state.salesPersons.find(person => parseInt(person.id) === id);
  },

  salesPersonsFilterCount: state => () => {
    const salesPersonsFilter = state.filter.salesPersons;

    return salesPersonsFilter ? salesPersonsFilter.length : 0;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
