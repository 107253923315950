import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

import de from "vuetify/src/locale/de.ts";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { de },
    current: "de"
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: "#5867dd",
        secondary: "#e8ecfa",
        accent: "#5d78ff",
        error: "#fd397a",
        info: "#5578eb",
        success: "#0abb87",
        warning: "#ffb822",
        red: "#E12D39",
        orange: "#F35627",
        yellow: "#E9B949",
        lightgreen: "#27AB83",
        green: "#3F9142",
        lightblue: "#2BB0ED",
        blue: "#4C63B6",
        purple: "#8950FC"
      }
    }
  }
});
