const state = {
  alerts: []
};

const mutations = {
  createAlert: (state, alert) => {
    state.alerts.push(alert);
  }
};

const actions = {
  createAlert: ({ commit }, alert) => {
    commit("createAlert", alert);
  }
};

const getters = {
  alerts: state => state.alerts
};

export default {
  state,
  mutations,
  actions,
  getters
};
