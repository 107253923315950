import ApiService from "../api.service";
import IndexedDB from "../indexeddb.service";

const state = {
  products: [],
  loadedAddressProductsCount: 0,
  maxAddressProductsCount: 0
};

const mutations = {
  setProducts: (state, products) => {
    state.products = products;
  },
  resetLoadedAddressProductsCount: state => {
    state.loadedAddressProductsCount = 0;
  }
};

const actions = {
  async updateAddressProductStatus({ state, commit }) {
    let addressProducts = [];

    await ApiService.post("products", {}).then(response => {
      let products = response.data.meta.products.kpi;

      commit("setProducts", products);

      if (response.data.items.length > 0) {
        addressProducts = response.data.items;
      }
    });

    // let addresses = await IndexedDB.getStorage("addresses");
    state.maxAddressProductsCount = addressProducts.length;

    commit("resetLoadedAddressProductsCount");

    for (let i = 0; i < addressProducts.length; i++) {
      const addressProduct = addressProducts[i];

      let address = await IndexedDB.getElement(
        "addresses",
        addressProduct.address.id
      );

      if (address) {
        address.customers = addressProduct.customers;

        await IndexedDB.saveToStorage("addresses", {
          key: address.address.id,
          value: address
        });
      }

      state.loadedAddressProductsCount++;
    }
  },
  resetLoadedAddressProductCount({ commit }) {
    commit("resetLoadedAddressProductsCount");
  }
};

const getters = {
  loadedAddressProductsCount: state => state.loadedAddressProductsCount,
  maxAddressProductsCount: state => state.maxAddressProductsCount
};

export default {
  state,
  mutations,
  actions,
  getters
};
