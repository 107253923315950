class AuthorizationService {
  currentPermissions = [];

  can(permission) {
    return this.currentPermissions.includes(permission);
  }

  setPermissions(permissions) {
    this.currentPermissions = permissions;
  }

  savePermissions(permissions) {
    window.localStorage.setItem("userPermissions", permissions);
  }

  retrievePermissions() {
    return window.localStorage.getItem("userPermissions");
  }

  removeStoredPermission() {
    return window.localStorage.removeItem("userPermissions");
  }
}

export default AuthorizationService;
