import Vue from "vue";
import axios from "axios";
import router from "../../router";
import store from "./store/index";
import { LOGOUT } from "@/core/services/store/auth.module";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";

const baseURL = process.env.VUE_APP_LARAVEL_BACKEND_URI;
/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = baseURL;
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Token ${JwtService.getToken()}`;

    Vue.axios.defaults.headers.common[
      "x-auth-token"
    ] = `${JwtService.getToken()}`;

    Vue.axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch(error => {
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = "") {
    slug = slug !== "" ? "/" + slug : slug;

    return Vue.axios.get(`${resource}${slug}`).catch(error => {
      checkErrorStatus(error.response.status);

      return {
        status: error.response.status
      };
    });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return Vue.axios.post(`${resource}`, params).catch(error => {
      checkErrorStatus(error.response.status);

      return {
        status: error.response.status
      };
    });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  postWithoutErrorHandling(resource, params) {
    return Vue.axios.post(`${resource}`, params);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    slug = slug !== "" ? "/" + slug : slug;

    return Vue.axios.put(`${resource}/${slug}`, params).catch(error => {
      checkErrorStatus(error.response.status);

      return {
        status: error.response.status
      };
    });
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params).catch(error => {
      checkErrorStatus(error.response.status);

      return {
        status: error.response.status
      };
    });
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return Vue.axios.delete(resource).catch(error => {
      checkErrorStatus(error.response.status);

      return {
        status: error.response.status
      };
    });
  }
};

const checkErrorStatus = status => {
  switch (status) {
    case 401:
    case 403:
      store
        .dispatch(LOGOUT)
        .then(() => router.push({ name: "login" }))
        .catch(() => {});
  }
};

export default ApiService;
